<template>
  <div class="card-body">
    <div
      class="row"
      v-if="$route.params.tipo_control == 1 || tipo_control == 1 && $parent.viaje"
    >
      <!-- Div Viaje -->
      <div class="col-md-6 col-sm-12 col-xs-12">
        <div class="card card-info card-outline collapsed-card">
          <div class="card-header pt-2 pb-2">
            <h3 class="card-title">
              <i class="fa fa-truck text-secondary"></i> Viaje
            </h3>
            <div class="card-tools">
              <button
                type="button"
                data-card-widget="collapse"
                data-toggle="tooltip"
                title="Collapse"
                class="btn btn-tool"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <label for="id_viaje_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>No:</b></label
              >
              <span
                id="id_viaje_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.viaje.id }}</span
              >
            </div>
            <div class="row">
              <label
                v-if="$parent.viaje.guia && $parent.viaje.guia != null"
                for="No_Guia_info"
                class="col-md-4 col-sm-4 col-xs-6"
                ><b>No. Guia: </b></label
              >
              <label
                v-else
                for="No_Ticket_info"
                class="col-md-4 col-sm-4 col-xs-6"
                >No. Ticket</label
              >

              <span
                id="No_Guia_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.viaje.guia && $parent.viaje.guia != null"
                >{{ $parent.viaje.guia.numero }} -
                {{ $parent.viaje.guia.digito_verificacion }}</span
              >

              <span
                id="No_Ticket_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-else-if="$parent.viaje.ticket && $parent.viaje.ticket != null"
                >{{ $parent.viaje.ticket.numero_ticket }}</span
              >
              <span
                v-else
                id="No_Guia_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >Sin Asignar</span
              >
            </div>
            <div class="row">
              <label for="id_viaje_info_temp" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Temperatura:</b></label
              >
              <span
                v-if="$parent.viaje.guia && $parent.viaje.guia !== null"
                id="id_viaje_info_temp"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{
                  $parent.viaje.guia.det_guias[0].analisis_laboratorio
                }}°</span
              >
            </div>
            <div class="row" v-if="$parent.viaje.clase_viaje">
              <label for="Clase_Viaje_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Clase: </b></label
              >
              <span
                id="Clase_Viaje_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.viaje.clase_viaje == 1"
                >Cargue</span
              >
              <span
                id="Clase_Viaje_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-else
                >Descargue</span
              >
            </div>
            <div class="row">
              <label
                for="No_Orden_Cargue_info"
                class="col-md-4 col-sm-4 col-xs-6"
                ><b>No. Orden Cargue: </b></label
              >
              <span
                id="No_Orden_Cargue_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.viaje.orden_de_cargue }}</span
              >
            </div>
            <div class="row">
              <label for="Estado_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Estado: </b></label
              >
              <span
                id="Estado_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.viaje.estadoViaje }}</span
              >
            </div>
            <div class="row">
              <label
                for="Sitio_Descargue_info"
                class="col-md-4 col-sm-4 col-xs-6"
                ><b>Despachado A: </b></label
              >
              <span
                id="Sitio_Descargue_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{
                  $parent.viaje.ruta ? $parent.viaje.ruta.despachado_a : ""
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- Detalle la ruta -->
      <div class="col-md-6 col-sm-12 col-xs-12">
        <div
          class="card card-outline collapsed-card"
          :class="$parent.viaje.ruta ? 'card-info' : 'card-danger'"
        >
          <div class="card-header pt-2 pb-2">
            <h3 class="card-title">
              <i class="fa fa-map text-secondary"></i> Orígenes - Destinos
            </h3>
            <div class="card-tools">
              <button
                type="button"
                data-card-widget="collapse"
                data-toggle="tooltip"
                title="Collapse"
                class="btn btn-tool"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <label for="Ruta_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Ruta: </b></label
              >
              <span
                id="Ruta_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.viaje.ruta ? $parent.viaje.ruta.nombre : "" }}</span
              >
            </div>
            <div class="row">
              <label for="Ruta_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Tipo Ruta: </b></label
              >
              <span
                id="Ruta_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.viaje.ruta && $parent.viaje.ruta.tipo_ruta == 1"
                >Ruta Corta</span
              >
              <span
                id="Ruta_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-else
                >Ruta Larga</span
              >
            </div>
            <div class="row">
              <label for="Sitio_Cargue_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Sitio Cargue: </b></label
              >
              <span
                id="Sitio_Cargue_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{
                  $parent.viaje.ruta ? $parent.viaje.ruta.origen.nombre : ""
                }}</span
              >
            </div>
            <div class="row">
              <label
                for="Sitio_Descargue_info"
                class="col-md-4 col-sm-4 col-xs-6"
                ><b>Sitio Descargue: </b></label
              >
              <span
                id="Sitio_Descargue_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{
                  $parent.viaje.ruta ? $parent.viaje.ruta.destino.nombre : ""
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- Detalle de la carga -->
      <div
        v-if="
          ($parent.viaje.tipo_producto.tipo_producto != 1 ||
            $parent.viaje.tipo_producto.tipo_producto != 6) &&
            $parent.viaje.ticket != null
        "
        class="col-md-6 col-sm-12 col-xs-12"
      >
        <div
          class="card card-outline collapsed-card"
          :class="$parent.viaje.ticket != null ? 'card-info' : 'card-danger'"
        >
          <div class="card-header pt-2 pb-2">
            <h3 class="card-title">
              <i class="fa fa-align-justify text-secondary"></i> Detalle de
              Carga
            </h3>
            <div class="card-tools">
              <button
                type="button"
                data-card-widget="collapse"
                data-toggle="tooltip"
                title="Collapse"
                class="btn btn-tool"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <label for="Producto_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Producto: </b></label
              >
              <span
                id="Producto_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.viaje.ticket && $parent.viaje.ticket != null"
                >{{ $parent.viaje.ticket.producto.nombre }}</span
              >
            </div>
            <div class="row">
              <label for="porc_agua_libre" class="col-md-4 col-sm-4 col-xs-6"
                ><b>% Agua libre:</b></label
              >
              <span
                id="porc_agua_libre_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.viaje.ticket && $parent.viaje.ticket != null"
                >{{ $parent.viaje.ticket.porc_agua_libre }}</span
              >
            </div>
            <div class="row">
              <label for="porc_crudo" class="col-md-4 col-sm-4 col-xs-6"
                ><b>% Crudo : </b></label
              >
              <span
                id="porc_crudo_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.viaje.ticket && $parent.viaje.ticket != null"
                >{{ $parent.viaje.ticket.porc_crudo }}</span
              >
            </div>
            <div class="row">
              <label for="porc_solidos" class="col-md-4 col-sm-4 col-xs-6"
                ><b>% Solidos: </b></label
              >
              <span
                id="porc_solidos_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.viaje.ticket && $parent.viaje.ticket != null"
                >{{ $parent.viaje.ticket.porc_solidos }}</span
              >
            </div>
            <div class="row">
              <label for="barriles_cargue" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Barriles cargue: </b></label
              >
              <span
                id="barriles_cargue_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.viaje.ticket && $parent.viaje.ticket != null"
                >{{ $parent.viaje.ticket.barriles_cargue }}</span
              >
            </div>
            <div class="row">
              <label
                for="fecha_llegada_cargue"
                class="col-md-4 col-sm-4 col-xs-6"
                ><b>Fecha llegada cargue: </b></label
              >
              <span
                id="fecha_llegada_cargue_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.viaje.ticket && $parent.viaje.ticket != null"
                >{{ $parent.viaje.ticket.fecha_llegada_cargue }}</span
              >
            </div>
            <div class="row">
              <label
                for="fecha_salida_cargue"
                class="col-md-4 col-sm-4 col-xs-6"
                ><b>Fecha salida cargue: </b></label
              >
              <span
                id="fecha_salida_cargue_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.viaje.ticket && $parent.viaje.ticket != null"
                >{{ $parent.viaje.ticket.fecha_salida_cargue }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- 13351630601 -->
      <!-- Fechas Enturnes -->
      <div
        :class="
          $parent.viaje.guia
            ? 'col-md-12 col-sm-12 col-xs-12'
            : 'col-md-6 col-sm-12 col-xs-12'
        "
      >
        <div
          class="card card-outline collapsed-card"
          :class="
            $parent.viaje.trazas_fechas_viaje != null
              ? 'card-info'
              : 'card-danger'
          "
        >
          <div class="card-header pt-2 pb-2">
            <h3 class="card-title">
              <i class="fa fa-align-justify text-secondary"></i> Fechas Enturnes
            </h3>
            <div class="card-tools">
              <button
                type="button"
                data-card-widget="collapse"
                data-toggle="tooltip"
                title="Collapse"
                class="btn btn-tool"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <label for="Producto_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Ventana Cargue: </b></label
              >
              <span
                id="VentanaCargue_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="
                  $parent.viaje.ventana_cargue &&
                    $parent.viaje.ventana_cargue != null
                "
                >{{ $parent.viaje.ventana_cargue.nombre }}</span
              >
            </div>
            <div class="row">
              <label for="API_Producto_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Fecha enturne cargue: </b></label
              >
              <span
                id="FechaEnturneCargue_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="
                  $parent.viaje.trazas_fechas_viaje &&
                    $parent.viaje.trazas_fechas_viaje != null
                "
                >{{
                  $parent.viaje.trazas_fechas_viaje.fecha_enturne_cargue
                }}</span
              >
            </div>
            <div class="row">
              <label for="vol_gov_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Ventana Descargue: </b></label
              >
              <span
                id="vol_gov_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="
                  $parent.viaje.ventana_descargue &&
                    $parent.viaje.ventana_descargue != null
                "
                >{{ $parent.viaje.ventana_descargue.nombre }}</span
              >
            </div>
            <div class="row">
              <label
                for="fecha_enturne_descargue"
                class="col-md-4 col-sm-4 col-xs-6"
                ><b>Fecha enturne descargue: </b></label
              >
              <span
                id="fecha_enturne_descargue"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="
                  $parent.viaje.trazas_fechas_viaje &&
                    $parent.viaje.trazas_fechas_viaje != null
                "
                >{{
                  $parent.viaje.trazas_fechas_viaje.fecha_enturne_descargue
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- Detalle Descargue -->
      <template v-if="$parent.viaje.guia && $parent.viaje.guia != null">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div
            class="card card-outline collapsed-card"
            :class="
              $parent.viaje.trazas_fechas_viaje != null
                ? 'card-info'
                : 'card-danger'
            "
          >
            <div class="card-header pt-2 pb-2">
              <h3 class="card-title">
                <i class="fa fa-align-justify text-secondary"></i> Detalle
                Volumenes
              </h3>
              <div class="card-tools">
                <button
                  type="button"
                  data-card-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                  class="btn btn-tool"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>

            <div class="card-body text-sm">
              <div class="row">
                <div class="col-md-12">
                  <table
                    class="table table-bordered table-striped table-hover table-sm"
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="6"
                          style="text-align: center; vertical-align: middle"
                          class="bg-frontera-top-left pt-2 pb-2 text-white text-center"
                        >
                          {{
                            $parent.viaje.guia.det_guias[0].producto_liquido
                              .nombre
                          }}
                        </td>
                        <td
                          class="bg-frontera-top-left pt-2 pb-2 text-white text-center"
                        ></td>
                        <td
                          class="bg-frontera-top-left pt-2 pb-2 text-white text-center"
                        >
                          <strong>Cargue</strong>
                        </td>
                        <td
                          class="bg-frontera-top-left pt-2 pb-2 text-white text-center"
                        >
                          <strong>Descargue</strong>
                        </td>
                        <td
                          class="bg-frontera-top-left pt-2 pb-2 text-white text-center"
                        >
                          <strong>Porcentaje</strong>
                        </td>
                        <td
                          class="bg-frontera-top-left pt-2 pb-2 text-white text-center"
                        >
                          <strong>Diferencia</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>API:</td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          {{ $parent.viaje.guia.det_guias[0].api }}
                        </td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia &&
                              $parent.viaje.guia != null &&
                              $parent.viaje.guia.cierre_guias &&
                              $parent.viaje.guia.cierre_guias != null &&
                              $parent.viaje.guia.cierre_guias.length > 0
                          "
                        >
                          {{ $parent.viaje.guia.cierre_guias[0].api }}
                        </td>
                        <td
                          class="text-center"
                          v-else-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          <span class="badge badge-secondary"
                            >Guía sin cierre</span
                          >
                        </td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia &&
                              $parent.viaje.guia != null &&
                              $parent.viaje.guia.cierre_guias &&
                              $parent.viaje.guia.cierre_guias != null &&
                              $parent.viaje.guia.cierre_guias.length > 0
                          "
                        >
                          {{ $parent.viaje.guia.cierre_guias[0].diferencia_api
                          }}<small>(%)</small>
                        </td>
                        <td
                          class="text-center"
                          v-else-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          <span class="badge badge-secondary"
                            >Guía sin cierre</span
                          >
                        </td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia &&
                              $parent.viaje.guia != null &&
                              $parent.viaje.guia.cierre_guias &&
                              $parent.viaje.guia.cierre_guias != null &&
                              $parent.viaje.guia.cierre_guias.length > 0
                          "
                        >
                          {{
                            (
                              $parent.viaje.guia.cierre_guias[0].api -
                              $parent.viaje.guia.det_guias[0].api
                            ).toFixed(2)
                          }}
                        </td>
                        <td
                          class="text-center"
                          v-else-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          <span class="badge badge-secondary"
                            >Guía sin cierre</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Vol. GOV:</td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          {{ $parent.viaje.guia.det_guias[0].gov }}
                        </td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia &&
                              $parent.viaje.guia != null &&
                              $parent.viaje.guia.cierre_guias &&
                              $parent.viaje.guia.cierre_guias != null &&
                              $parent.viaje.guia.cierre_guias.length > 0
                          "
                        >
                          {{ $parent.viaje.guia.cierre_guias[0].barriles_gov }}
                        </td>
                        <td
                          class="text-center"
                          v-else-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          <span class="badge badge-secondary"
                            >Guía sin cierre</span
                          >
                        </td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia &&
                              $parent.viaje.guia != null &&
                              $parent.viaje.guia.cierre_guias &&
                              $parent.viaje.guia.cierre_guias != null &&
                              $parent.viaje.guia.cierre_guias.length > 0
                          "
                        >
                          {{ $parent.viaje.guia.cierre_guias[0].diferencia_gov
                          }}<small>(%)</small>
                        </td>
                        <td
                          class="text-center"
                          v-else-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          <span class="badge badge-secondary"
                            >Guía sin cierre</span
                          >
                        </td>

                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia &&
                              $parent.viaje.guia != null &&
                              $parent.viaje.guia.cierre_guias &&
                              $parent.viaje.guia.cierre_guias != null &&
                              $parent.viaje.guia.cierre_guias.length > 0
                          "
                        >
                          {{
                            (
                              $parent.viaje.guia.cierre_guias[0].barriles_gov -
                              $parent.viaje.guia.det_guias[0].gov
                            ).toFixed(2)
                          }}
                        </td>
                        <td
                          class="text-center"
                          v-else-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          <span class="badge badge-secondary"
                            >Guía sin cierre</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Vol. GSV:</td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          {{ $parent.viaje.guia.det_guias[0].gsv }}
                        </td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia &&
                              $parent.viaje.guia != null &&
                              $parent.viaje.guia.cierre_guias &&
                              $parent.viaje.guia.cierre_guias != null &&
                              $parent.viaje.guia.cierre_guias.length > 0
                          "
                        >
                          {{ $parent.viaje.guia.cierre_guias[0].barriles_gsv }}
                        </td>
                        <td
                          class="text-center"
                          v-else-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          <span class="badge badge-secondary"
                            >Guía sin cierre</span
                          >
                        </td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia &&
                              $parent.viaje.guia != null &&
                              $parent.viaje.guia.cierre_guias &&
                              $parent.viaje.guia.cierre_guias != null &&
                              $parent.viaje.guia.cierre_guias.length > 0
                          "
                        >
                          {{ $parent.viaje.guia.cierre_guias[0].diferencia_gsv
                          }}<small>(%)</small>
                        </td>
                        <td
                          class="text-center"
                          v-else-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          <span class="badge badge-secondary"
                            >Guía sin cierre</span
                          >
                        </td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia &&
                              $parent.viaje.guia != null &&
                              $parent.viaje.guia.cierre_guias &&
                              $parent.viaje.guia.cierre_guias != null &&
                              $parent.viaje.guia.cierre_guias.length > 0
                          "
                        >
                          {{
                            (
                              $parent.viaje.guia.cierre_guias[0].barriles_gsv -
                              $parent.viaje.guia.det_guias[0].gsv
                            ).toFixed(2)
                          }}
                        </td>
                        <td
                          class="text-center"
                          v-else-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          <span class="badge badge-secondary"
                            >Guía sin cierre</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Vol. NSV:</td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          {{ $parent.viaje.guia.det_guias[0].nsv }}
                        </td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia &&
                              $parent.viaje.guia != null &&
                              $parent.viaje.guia.cierre_guias &&
                              $parent.viaje.guia.cierre_guias != null &&
                              $parent.viaje.guia.cierre_guias.length > 0
                          "
                        >
                          {{ $parent.viaje.guia.cierre_guias[0].barriles_nsv }}
                        </td>
                        <td
                          class="text-center"
                          v-else-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          <span class="badge badge-secondary"
                            >Guía sin cierre</span
                          >
                        </td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia &&
                              $parent.viaje.guia != null &&
                              $parent.viaje.guia.cierre_guias &&
                              $parent.viaje.guia.cierre_guias != null &&
                              $parent.viaje.guia.cierre_guias.length > 0
                          "
                        >
                          {{ $parent.viaje.guia.cierre_guias[0].diferencia_nsv
                          }}<small>(%)</small>
                        </td>
                        <td
                          class="text-center"
                          v-else-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          <span class="badge badge-secondary"
                            >Guía sin cierre</span
                          >
                        </td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia &&
                              $parent.viaje.guia != null &&
                              $parent.viaje.guia.cierre_guias &&
                              $parent.viaje.guia.cierre_guias != null &&
                              $parent.viaje.guia.cierre_guias.length > 0
                          "
                        >
                          {{
                            (
                              $parent.viaje.guia.cierre_guias[0].barriles_nsv -
                              $parent.viaje.guia.det_guias[0].nsv
                            ).toFixed(2)
                          }}
                        </td>
                        <td
                          class="text-center"
                          v-else-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          <span class="badge badge-secondary"
                            >Guía sin cierre</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>BSW:</td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          {{ $parent.viaje.guia.det_guias[0].bsw }}
                        </td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia &&
                              $parent.viaje.guia != null &&
                              $parent.viaje.guia.cierre_guias &&
                              $parent.viaje.guia.cierre_guias != null &&
                              $parent.viaje.guia.cierre_guias.length > 0
                          "
                        >
                          {{
                            $parent.viaje.guia.cierre_guias[0].porcentaje_bsw
                          }}
                        </td>
                        <td
                          class="text-center"
                          v-else-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          <span class="badge badge-secondary"
                            >Guía sin cierre</span
                          >
                        </td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia &&
                              $parent.viaje.guia != null &&
                              $parent.viaje.guia.cierre_guias &&
                              $parent.viaje.guia.cierre_guias != null &&
                              $parent.viaje.guia.cierre_guias.length > 0
                          "
                        >
                          {{ $parent.viaje.guia.cierre_guias[0].diferencia_bsw
                          }}<small>(%)</small>
                        </td>
                        <td
                          class="text-center"
                          v-else-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          <span class="badge badge-secondary"
                            >Guía sin cierre</span
                          >
                        </td>
                        <td
                          class="text-right"
                          v-if="
                            $parent.viaje.guia &&
                              $parent.viaje.guia != null &&
                              $parent.viaje.guia.cierre_guias &&
                              $parent.viaje.guia.cierre_guias != null &&
                              $parent.viaje.guia.cierre_guias.length > 0
                          "
                        >
                          {{
                            (
                              $parent.viaje.guia.cierre_guias[0]
                                .porcentaje_bsw -
                              $parent.viaje.guia.det_guias[0].bsw
                            ).toFixed(2)
                          }}
                        </td>
                        <td
                          class="text-center"
                          v-else-if="
                            $parent.viaje.guia && $parent.viaje.guia != null
                          "
                        >
                          <span class="badge badge-secondary"
                            >Guía sin cierre</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Div Unidad de transporte-->
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="card card-info card-outline collapsed-card">
          <div class="card-header pt-2 pb-2">
            <h3 class="card-title">
              <i class="fa fa-id-card text-secondary"></i> Unidad de Transporte
            </h3>
            <div class="card-tools">
              <button
                type="button"
                data-card-widget="collapse"
                data-toggle="tooltip"
                title="Collapse"
                class="btn btn-tool"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <label
                for="Identificacion_info"
                class="col-md-6 col-sm-6 col-xs-6"
                ><b>Empresa Transportadora: </b></label
              >
              <span
                id="Identificacion_info"
                class="col-md-6 col-sm-6 col-xs-6 text-right"
                >{{
                  $parent.viaje.transportadora
                    ? $parent.viaje.transportadora.razon_social
                    : ""
                }}</span
              >
            </div>
            <div class="row">
              <!-- Información del Conductor -->
              <div class="form-group col-md-4 col-sm-6 col-xs-12 text-center">
                <span><strong>Conductor</strong></span>
                <div>
                  <span v-if="$parent.viaje.conductor"
                    >{{ $parent.viaje.conductor.nombres }}
                    {{ $parent.viaje.conductor.apellidos }}</span
                  >
                </div>
                <div>
                  <small
                    >Documento:
                    {{
                      $parent.viaje.conductor
                        ? $parent.viaje.conductor.numero_documento
                        : ""
                    }}</small
                  >
                </div>
                <div>
                  <small
                    >Tel:
                    {{
                      $parent.viaje.conductor
                        ? $parent.viaje.conductor.celular_principal
                        : ""
                    }}</small
                  >
                </div>
                <div
                  class="m-3"
                  v-if="
                    $parent.viaje.conductor &&
                      $parent.viaje.conductor.link_fotografia
                  "
                >
                  <a
                    :href="uri_docs + $parent.viaje.conductor.link_fotografia"
                    target="_blank"
                    ><img
                      :src="uri_docs + $parent.viaje.conductor.link_fotografia"
                      alt="Conductor"
                      class="rounded mx-auto d-block"
                      width="150"
                  /></a>
                </div>
              </div>
              <!-- Información del Vehículo -->
              <div class="form-group col-md-4 col-sm-6 col-xs-12 text-center">
                <span><strong>Vehículo</strong></span>
                <div>
                  <span>{{
                    $parent.viaje.vehiculo ? $parent.viaje.vehiculo.placa : ""
                  }}</span>
                </div>
                <div
                  class="m-3"
                  v-if="
                    $parent.viaje.vehiculo &&
                      $parent.viaje.vehiculo.link_fotografia
                  "
                >
                  <a
                    :href="uri_docs + $parent.viaje.vehiculo.link_fotografia"
                    target="_blank"
                    ><img
                      :src="uri_docs + $parent.viaje.vehiculo.link_fotografia"
                      alt="Vehículo"
                      class="rounded mx-auto d-block"
                      width="150"
                  /></a>
                </div>
              </div>
              <!-- Información del Vehículo -->
              <div
                v-if="$parent.viaje.remolque"
                class="form-group col-md-4 col-sm-6 col-xs-12 text-center"
              >
                <span><strong>Remolque</strong></span>
                <div>
                  <span>{{ $parent.viaje.remolque.placa }}</span>
                </div>
                <div class="m-3" v-if="$parent.viaje.remolque.link_fotografia">
                  <a
                    :href="uri_docs + $parent.viaje.remolque.link_fotografia"
                    target="_blank"
                    ><img
                      :src="uri_docs + $parent.viaje.remolque.link_fotografia"
                      alt="Remolque"
                      class="rounded mx-auto d-block"
                      width="150"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <!-- Div Viaje -->
      <div class="col-md-6 col-sm-12 col-xs-12">
        <div class="card card-info card-outline collapsed-card">
          <div class="card-header pt-2 pb-2">
            <h3 class="card-title">
              <i class="fa fa-truck text-secondary"></i> Viaje
            </h3>
            <div class="card-tools">
              <button
                type="button"
                data-card-widget="collapse"
                data-toggle="tooltip"
                title="Collapse"
                class="btn btn-tool"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <label for="id_viaje_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>No:</b></label
              >
              <span
                id="id_viaje_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.viaje.id }}</span
              >
            </div>
            <div class="row">
              <label for="No_Guia_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>No. Guia: </b></label
              >
              <span
                id="No_Guia_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.viaje.guia && $parent.viaje.guia != null"
                >{{ $parent.viaje.guia.numero }} -
                {{ $parent.viaje.guia.digito_verificacion }}</span
              >
              <span
                id="No_Guia_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-else
                >Sin Asignar</span
              >
            </div>
            <div class="row">
              <label for="id_viaje_info_temp" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Temperatura:</b></label
              >
              <span
                v-if="$parent.viaje.guia && $parent.viaje.guia !== null"
                id="id_viaje_info_temp"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.viaje.guia.analisis_laboratorio }}°</span
              >
            </div>
            <div class="row" v-if="$parent.viaje.clase_viaje">
              <label for="Clase_Viaje_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Clase: </b></label
              >
              <span
                id="Clase_Viaje_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.viaje.clase_viaje == 1"
                >Cargue</span
              >
              <span
                id="Clase_Viaje_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-else
                >Descargue</span
              >
            </div>
            <div class="row">
              <label
                for="No_Orden_Cargue_info"
                class="col-md-4 col-sm-4 col-xs-6"
                ><b>No. Orden Cargue: </b></label
              >
              <span
                id="No_Orden_Cargue_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.viaje.orden_de_cargue }}</span
              >
            </div>
            <div class="row">
              <label for="Estado_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Estado: </b></label
              >
              <span
                id="Estado_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.viaje.estadoViaje }}</span
              >
            </div>
            <div class="row">
              <label
                for="Sitio_Descargue_info"
                class="col-md-4 col-sm-4 col-xs-6"
                ><b>Despachado A: </b></label
              >
              <span
                id="Sitio_Descargue_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.viaje.despachado_a }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- Detalle de la carga -->
      <div class="col-md-6 col-sm-12 col-xs-12">
        <div
          class="card card-outline collapsed-card"
          :class="$parent.viaje.guia != null ? 'card-info' : 'card-danger'"
        >
          <div class="card-header pt-2 pb-2">
            <h3 class="card-title">
              <i class="fa fa-align-justify text-secondary"></i> Detalle de
              Carga
            </h3>
            <div class="card-tools">
              <button
                type="button"
                data-card-widget="collapse"
                data-toggle="tooltip"
                title="Collapse"
                class="btn btn-tool"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <label for="Producto_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Producto: </b></label
              >
              <span
                id="Producto_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.viaje.guia && $parent.viaje.guia != null"
                >{{ $parent.viaje.producto_liquido.nombre }}</span
              >
            </div>
            <div class="row">
              <label for="API_Producto_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>API Producto: </b></label
              >
              <span
                id="API_Producto_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.viaje.guia && $parent.viaje.guia != null"
                >{{ $parent.viaje.producto_liquido.api }}</span
              >
            </div>
            <div class="row">
              <label for="vol_gov_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Vol. GOV: </b></label
              >
              <span
                id="vol_gov_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.viaje.guia && $parent.viaje.guia != null"
                >{{ $parent.viaje.gov }}</span
              >
            </div>
            <div class="row">
              <label for="vol_gsv_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Vol. GSV: </b></label
              >
              <span
                id="vol_gsv_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.viaje.guia && $parent.viaje.guia != null"
                >{{ $parent.viaje.gsv }}</span
              >
            </div>
            <div class="row">
              <label for="vol_nsv_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Vol. NSV: </b></label
              >
              <span
                id="vol_nsv_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.viaje.guia && $parent.viaje.guia != null"
                >{{ $parent.viaje.nsv }}</span
              >
            </div>
            <div class="row">
              <label for="vol_bsw_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Vol. BSW: </b></label
              >
              <span
                id="vol_bsw_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                v-if="$parent.viaje.guia && $parent.viaje.guia != null"
                >{{ $parent.viaje.bsw }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- Detalle la ruta -->
      <!-- <div class="col-md-6 col-sm-12 col-xs-12">
        <div class="card card-info card-outline collapsed-card">
          <div class="card-header pt-2 pb-2">
            <h3 class="card-title">
              <i class="fa fa-map text-secondary"></i> Orígenes - Destinos
            </h3>
            <div class="card-tools">
              <button
                type="button"
                data-card-widget="collapse"
                data-toggle="tooltip"
                title="Collapse"
                class="btn btn-tool"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <label for="Ruta_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Ruta: </b></label
              >
              <span id="Ruta_info" class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.viaje.sitio_origen.nombre }} -
                {{ $parent.viaje.sitio_destino }}</span
              >
            </div>
            <div class="row">
              <label for="Sitio_Cargue_info" class="col-md-4 col-sm-4 col-xs-6"
                ><b>Sitio Cargue: </b></label
              >
              <span
                id="Sitio_Cargue_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.viaje.sitio_origen.nombre }}</span
              >
            </div>
            <div class="row">
              <label
                for="Sitio_Descargue_info"
                class="col-md-4 col-sm-4 col-xs-6"
                ><b>Sitio Descargue: </b></label
              >
              <span
                id="Sitio_Descargue_info"
                class="col-md-8 col-sm-8 col-xs-6 text-right"
                >{{ $parent.viaje.sitio_destino }}</span
              >
            </div>
          </div>
        </div>
      </div> -->

      <!-- Div Unidad de transporte-->
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="card card-info card-outline collapsed-card">
          <div class="card-header pt-2 pb-2">
            <h3 class="card-title">
              <i class="fa fa-id-card text-secondary"></i> Unidad de Transporte
            </h3>
            <div class="card-tools">
              <button
                type="button"
                data-card-widget="collapse"
                data-toggle="tooltip"
                title="Collapse"
                class="btn btn-tool"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <label
                for="Identificacion_info"
                class="col-md-6 col-sm-6 col-xs-6"
                ><b>Empresa Transportadora: </b></label
              >
              <span
                id="Identificacion_info"
                class="col-md-6 col-sm-6 col-xs-6 text-right"
                >{{
                  $parent.viaje.transportadora
                    ? $parent.viaje.transportadora.razon_social
                    : ""
                }}</span
              >
            </div>
            <div class="row">
              <!-- Información del Conductor -->
              <div class="form-group col-md-4 col-sm-6 col-xs-12 text-center">
                <span><strong>Conductor</strong></span>
                <div>
                  <span v-if="$parent.viaje.conductor">{{
                    $parent.viaje.conductor
                  }}</span>
                </div>
                <div>
                  <small
                    >Documento: {{ $parent.viaje.documento_conductor }}</small
                  >
                </div>
              </div>
              <!-- Información del Vehículo -->
              <div class="form-group col-md-4 col-sm-6 col-xs-12 text-center">
                <span><strong>Vehículo</strong></span>
                <div>
                  <span>{{ $parent.viaje.vehiculo }}</span>
                </div>
              </div>
              <!-- Información del Vehículo -->
              <div
                v-if="$parent.viaje.remolque"
                class="form-group col-md-4 col-sm-6 col-xs-12 text-center"
              >
                <span><strong>Remolque</strong></span>
                <div>
                  <span>{{ $parent.viaje.remolque }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ViajesInfoViaje",
  data() {
    return {
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      tipo_control: 0,
    };
  },
};
</script>
