<template>
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="tab-VehAct"
            data-toggle="tab"
            href="#VehAct"
            v-show="Object.keys($parent.vehiculosAct).length > 0"
            ><b>Activos</b></a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="tab-VehPost"
            data-toggle="tab"
            href="#VehPost"
            v-show="Object.keys($parent.vehiculosPost).length > 0"
            ><b>Postulados</b></a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="tab-VehInact"
            data-toggle="tab"
            href="#VehInact"
            v-show="Object.keys($parent.vehiculosInact).length > 0"
            ><b>Inactivos</b></a
          >
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade active show"
          id="VehAct"
          v-show="Object.keys($parent.vehiculosAct).length > 0"
        >
          <div class="card-body table-responsive">
            <div class="row">
              <div class="col-md-12">
                <table
                  id="activos"
                  class="table table-bordered table-striped table-hover table-sm text-nowrap"
                >
                  <thead>
                    <tr>
                      <th colspan="10"></th>
                      <th colspan="2" style="text-align: center">
                        Último Registro
                      </th>
                    </tr>
                    <tr>
                      <th>
                        Viaje
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.viajeAct"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Guía
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.guiaAct"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Ticket
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.ticketAct"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Vehículo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.vehiAct"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Tipo Combust.
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="filtros.tipoCombAct"
                          placeholder="Tipo Combustible"
                          label="descripcion"
                          :options="$parent.listasForms.tipos_combustibles"
                          @input="getTipoCombustible('activo')"
                          multiple
                        ></v-select>
                      </th>
                      <th>
                        Remolque
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.remAct"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Conductor
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.condAct"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Transportadora
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.transAct"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Ruta
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.rutaAct"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>Último Punto</th>
                      <th>Fecha Cita</th>
                      <th>Latitud</th>
                      <th>Longitud</th>
                      <th>Dirección</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in $parent.vehiculosAct.data"
                      :key="item.id"
                    >
                      <td>
                        <button
                          type="button"
                          class="btn btn-sm btn-default py-0"
                          data-toggle="modal"
                          data-target="#modal-form-detViaje"
                          @click="
                            llenarModalDetViaje(
                              item.viajes[0].id,
                              item.viajes[0].tipo_operacion
                            )
                          "
                        >
                          {{ item.viajes[0].id }}
                        </button>
                      </td>
                      <td
                        v-if="
                          item.viajes[0].guia !== null &&
                            item.viajes[0].guia !== undefined
                        "
                      >
                        <span class="badge badge-success">
                          {{ item.viajes[0].guia.numero }}-{{
                            item.viajes[0].guia.digito_verificacion
                          }}
                        </span>
                      </td>
                      <td v-else>
                        <span class="badge badge-danger"> Sin Asignar </span>
                      </td>
                      <td
                        v-if="
                          item.viajes[0].ticket !== null &&
                            item.viajes[0].ticket !== undefined
                        "
                      >
                        <span class="badge badge-success">
                          {{ item.viajes[0].ticket.numero_ticket }}
                        </span>
                      </td>
                      <td v-else>
                        <span class="badge badge-danger"> Sin Asignar </span>
                      </td>
                      <td class="text-center">
                        {{ item.placa }}
                      </td>
                      <td class="text-center">
                        <span
                          class="badge ml-1 mr-1"
                          :class="
                            item.tipo_combustible == 1
                              ? 'badge-warning'
                              : 'badge-info'
                          "
                        >
                          {{ item.nCombustible }}
                        </span>
                      </td>
                      <td>
                        {{ item.viajes[0].remolque.placa }}
                      </td>
                      <td>
                        {{ item.viajes[0].conductor.apellidos }}
                        {{ item.viajes[0].conductor.nombres }}
                      </td>
                      <td>
                        {{ item.viajes[0].transportadora.razon_social }}
                      </td>
                      <td v-if="item.viajes[0].rutaViaje">
                        {{ item.viajes[0].rutaViaje }}
                      </td>
                      <td v-else></td>
                      <td
                        v-if="
                          item.viajes[0].sitio !== null &&
                            item.viajes[0].sitio !== undefined
                        "
                      >
                        {{ item.viajes[0].sitio.nombre }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.viajes[0].cita_cargue !== null">
                        {{ item.viajes[0].cita_cargue }}
                      </td>
                      <td v-if="item.ultimo_registro != null">
                        {{ item.ultimo_registro.gps.dblLatitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro != null">
                        {{ item.ultimo_registro.gps.dblLongitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro != null">
                        {{ item.ultimo_registro.gps.strDireccion }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-left" v-if="$parent.vehiculosAct.total">
              <p>
                Mostrando del
                <b>{{ $parent.vehiculosAct.from }}</b> al
                <b>{{ $parent.vehiculosAct.to }}</b> de un total:
                <b>{{ $parent.vehiculosAct.total }}</b> Registros
              </p>
            </div>
            <div class="float-left" v-else>
              <p>
                <span class="badge badge-danger"
                  >No hay registros para mostrar</span
                >
              </p>
            </div>
            <pagination
              :data="$parent.vehiculosAct"
              @pagination-change-page="$parent.getIndex"
              :limit="10"
              align="right"
            ></pagination>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="VehPost"
          v-show="Object.keys($parent.vehiculosPost).length > 0"
        >
          <div class="card-body table-responsive">
            <table
              id="postulados"
              class="table table-bordered table-striped table-hover table-sm text-nowrap"
            >
              <thead>
                <tr>
                  <th colspan="5"></th>
                  <th colspan="2" style="text-align: center">
                    Último Registro
                  </th>
                </tr>
                <tr>
                  <th>
                    Vehículo
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.vehiPost"
                      @keyup.enter="$parent.getIndex()"
                    />
                  </th>
                  <th>
                    Tipo Combust.
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="filtros.tipoCombPost"
                      placeholder="Tipo Combustible"
                      label="descripcion"
                      :options="$parent.listasForms.tipos_combustibles"
                      @input="getTipoCombustible('postulado')"
                      multiple
                    ></v-select>
                  </th>
                  <th>
                    Remolque
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.remPost"
                      @keyup.enter="$parent.getIndex()"
                    />
                  </th>
                  <th>
                    Conductor
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.condPost"
                      @keyup.enter="$parent.getIndex()"
                    />
                  </th>
                  <th>
                    Transportadora
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.transPost"
                      @keyup.enter="$parent.getIndex()"
                    />
                  </th>
                  <th>Fecha Cita</th>
                  <th>Latitud</th>
                  <th>Longitud</th>
                  <th>Dirección</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in $parent.vehiculosPost.data" :key="item.id">
                  <td>
                    {{ item.placa }}
                  </td>
                  <td class="text-center">
                    <span
                      class="badge ml-1 mr-1"
                      :class="
                        item.tipo_combustible == 1
                          ? 'badge-warning'
                          : 'badge-info'
                      "
                    >
                      {{ item.nCombustible }}
                    </span>
                  </td>
                  <td>
                    {{
                      item.det_postulaciones[0] &&
                      item.det_postulaciones[0].remolque
                        ? item.det_postulaciones[0].remolque.placa
                        : ""
                    }}
                  </td>
                  <td>
                    {{
                      item.det_postulaciones[0] &&
                      item.det_postulaciones[0].conductor
                        ? item.det_postulaciones[0].conductor.apellidos
                        : ""
                    }}
                    {{
                      item.det_postulaciones[0] &&
                      item.det_postulaciones[0].conductor
                        ? item.det_postulaciones[0].conductor.nombres
                        : ""
                    }}
                  </td>
                  <td>
                    {{
                      item.det_postulaciones[0].det_solicitud_diaria.empresa
                        .razon_social
                    }}
                  </td>
                  <td>
                    {{
                      item.det_postulaciones[0] &&
                      item.det_postulaciones[0].det_solicitud_diaria
                        ? item.det_postulaciones[0].det_solicitud_diaria
                            .solicitud_diaria.fecha
                        : ""
                    }}
                    {{
                      item.det_postulaciones[0] &&
                      item.det_postulaciones[0].det_solicitud_diaria
                        ? item.det_postulaciones[0].det_solicitud_diaria.cita
                        : ""
                    }}
                  </td>
                  <td>
                    {{
                      item.ultimo_registro
                        ? item.ultimo_registro.gps.dblLatitud
                        : ""
                    }}
                  </td>
                  <td>
                    {{
                      item.ultimo_registro
                        ? item.ultimo_registro.gps.dblLongitud
                        : ""
                    }}
                  </td>
                  <td>
                    {{
                      item.ultimo_registro
                        ? item.ultimo_registro.gps.strDireccion
                        : ""
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <div class="float-left" v-if="$parent.vehiculosPost.total">
              <p>
                Mostrando del
                <b>{{ $parent.vehiculosPost.from }}</b> al
                <b>{{ $parent.vehiculosPost.to }}</b> de un total:
                <b>{{ $parent.vehiculosPost.total }}</b> Registros
              </p>
            </div>
            <div class="float-left" v-else>
              <p>
                <span class="badge badge-danger"
                  >No hay registros para mostrar</span
                >
              </p>
            </div>
            <pagination
              :data="$parent.vehiculosPost"
              @pagination-change-page="$parent.getIndex"
              :limit="10"
              align="right"
            ></pagination>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="VehInact"
          v-show="Object.keys($parent.vehiculosInact).length > 0"
        >
          <div class="card-body table-responsive">
            <table
              id="inactivos"
              class="table table-bordered table-striped table-hover table-sm text-nowrap"
            >
              <thead>
                <tr>
                  <th colspan="2"></th>
                  <th colspan="2" style="text-align: center">
                    Último Registro
                  </th>
                </tr>
                <tr>
                  <th>
                    Vehículo
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.vehiInact"
                      @keyup.enter="$parent.getIndex()"
                    />
                  </th>
                  <th>
                    Tipo Combust.
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="filtros.tipoCombInact"
                      placeholder="Tipo Combustible"
                      label="descripcion"
                      :options="$parent.listasForms.tipos_combustibles"
                      @input="getTipoCombustible('inactivo')"
                      multiple
                    ></v-select>
                  </th>
                  <th>
                    Transportadora
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.transInact"
                      @keyup.enter="$parent.getIndex()"
                    />
                  </th>
                  <th>Latitud</th>
                  <th>Longitud</th>
                  <th>Dirección</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in $parent.vehiculosInact.data" :key="item.id">
                  <td>
                    {{ item.placa }}
                  </td>
                  <td class="text-center">
                    <span
                      class="badge ml-1 mr-1"
                      :class="
                        item.tipo_combustible == 1
                          ? 'badge-warning'
                          : 'badge-info'
                      "
                    >
                      {{ item.nCombustible }}
                    </span>
                  </td>
                  <td>
                    {{ item.empresa.razon_social }}
                  </td>
                  <td v-if="item.ultimo_registro != null">
                    {{ item.ultimo_registro.gps.dblLatitud }}
                  </td>
                  <td v-else></td>
                  <td v-if="item.ultimo_registro != null">
                    {{ item.ultimo_registro.gps.dblLongitud }}
                  </td>
                  <td v-else></td>
                  <td v-if="item.ultimo_registro != null">
                    {{ item.ultimo_registro.gps.strDireccion }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <div class="float-left" v-if="$parent.vehiculosInact.total">
              <p>
                Mostrando del
                <b>{{ $parent.vehiculosInact.from }}</b> al
                <b>{{ $parent.vehiculosInact.to }}</b> de un total:
                <b>{{ $parent.vehiculosInact.total }}</b> Registros
              </p>
            </div>
            <div class="float-left" v-else>
              <p>
                <span class="badge badge-danger"
                  >No hay registros para mostrar</span
                >
              </p>
            </div>
            <pagination
              :data="$parent.vehiculosInact"
              @pagination-change-page="$parent.getIndex"
              :limit="10"
              align="right"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";

export default {
  name: "MapasListVehiculos",
  components: {
    pagination,
    vSelect,
  },
  data() {
    return {
      filtros: {},
    };
  },

  methods: {
    llenarModalDetViaje(viaje, tipo_control) {
      this.$parent.$refs.ViajesDetViaje.llenar_modal_detViaje(
        viaje,
        tipo_control
      );
    },

    getTipoCombustible(tipo) {
      this.filtros.tipo_combustible_act = [];
      this.filtros.tipo_combustible_post = [];
      this.filtros.tipo_combustible_inact = [];

      switch (tipo) {
        case "activo":
          this.filtros.tipoCombAct.forEach((tip) => {
            this.filtros.tipo_combustible_act.push(tip.numeracion);
          });
          break;

        case "postulado":
          this.filtros.tipoCombPost.forEach((tip) => {
            this.filtros.tipo_combustible_post.push(tip.numeracion);
          });
          break;

        case "inactivo":
          this.filtros.tipoCombInact.forEach((tip) => {
            this.filtros.tipo_combustible_inact.push(tip.numeracion);
          });
          break;
      }

      this.$parent.getIndex();
    },

    //Funcion para traer la direccion segun latitud y longitud
    /* decodeLatLong(lat, long) {
      this.$gmapApiPromiseLazy().then(() => {
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode(
          {
            location: {
              lat: parseInt(lat),
              lng: parseInt(long),
            },
          },
          (results, status) => {
            if (status === "OK") {
              this.getFromAutocomplete(results[0]);
            }
          }
        );
      });
    } */
  },
};
</script>
