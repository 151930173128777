<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-1">
                <h5 class="mt-2">Mapa</h5>
              </div>
              <div class="col-sm-7">
                <button
                  type="button"
                  class="btn bg-frontera text-white mr-2 align-top py-1"
                  data-toggle="modal"
                  data-target="#modal_aside_mapa"
                  style="cursor: pointer"
                >
                  <i class="fas fa-search-location" style="font-size: 21px"></i>
                  <div class="text-center">
                    <span class="badge bg-info">Buscar</span>
                  </div>
                </button>
                <label class="badge bg-frontera text-white mr-2 align-top">
                  <input
                    type="checkbox"
                    id="geocerca"
                    v-model="form.geocercas"
                    @change="showGeocercas()"
                  />
                  <i
                    style="font-size: 22px"
                    class="fas fa-map-marked-alt p-1"
                  ></i>
                  <div class="text-center">
                    <span class="badge bg-dark">Geocercas</span>
                  </div>
                </label>
                <label class="badge bg-frontera text-white mr-2 align-top">
                  <input
                    type="checkbox"
                    id="geocerca"
                    v-model="form.geocercasProhibidas"
                    @change="showGeocercasProhibidas()"
                  />
                  <i
                    style="font-size: 22px"
                    class="fas fa-map-marked-alt p-1"
                  ></i>
                  <div class="text-center">
                    <span class="badge bg-dark">Geocercas Prohibidas</span>
                  </div>
                </label>
                <label class="badge bg-frontera text-white mr-2">
                  <input
                    type="checkbox"
                    v-model="trazaViaje"
                    @change="showDraggable()"
                  />
                  <i style="font-size: 22px" class="fas fa-route p-1"></i>
                  <div class="text-center">
                    <span class="badge bg-dark">Trazabilidad</span>
                  </div>
                </label>
                <label class="badge bg-frontera text-white">
                  <input
                    type="checkbox"
                    v-model="form.carrosAct"
                    @change="showCarros()"
                  />
                  <i
                    style="font-size: 22px"
                    class="fas fa-truck-moving p-1"
                    v-b-popover.hover.top=""
                    title="Vehículos Activos"
                  ></i>
                  <div class="text-center">
                    <span class="badge bg-success">Activos</span>
                  </div>
                </label>
                <label class="badge bg-frontera text-white align-top ml-2">
                  <input
                    type="checkbox"
                    v-model="form.carrosPost"
                    @change="showCarros()"
                  />
                  <i
                    style="font-size: 22px"
                    class="fas fa-truck-moving p-1"
                    v-b-popover.hover.top=""
                    title="Vehículos Postulados"
                  ></i>
                  <div class="text-center">
                    <span class="badge bg-warning">Postulados</span>
                  </div>
                </label>
                <label class="badge bg-frontera text-white ml-2">
                  <input
                    type="checkbox"
                    v-model="form.carrosInact"
                    @change="showCarros()"
                  />
                  <i
                    style="font-size: 22px"
                    class="fas fa-truck-moving p-1"
                    v-b-popover.hover.top=""
                    title="Vehículos Inactivos"
                  ></i>
                  <div class="text-center">
                    <span class="badge bg-danger">Inactivos</span>
                  </div>
                </label>
                <button
                  type="button"
                  class="btn btn-dark ml-2 align-top py-1"
                  @click="showCarros()"
                  style="cursor: pointer"
                >
                  <i style="font-size: 22px" class="fas fa-sync"></i>
                  <div class="text-center">
                    <span class="badge bg-info">Actualizar</span>
                  </div>
                </button>
              </div>
              <div class="col-sm-4">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Consultas</li>
                  <li class="breadcrumb-item active">Mapas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <gmap-map
                      :center="center"
                      :zoom="zoom"
                      style="width: 100%; height: 650px"
                      :key="mapKey"
                    >
                      <gmap-marker
                        :key="index"
                        v-for="(m, index) in markersTraza"
                        :position="m.position"
                        :icon="m.icon"
                        :animation="2"
                        @click="toggleInfoWindow(m, index, m.info)"
                      ></gmap-marker>
                      <gmap-cluster
                        :gridSize="50"
                        :zoomOnClick="true"
                        :enableRetinaIcons="true"
                        :minimumClusterSize="3"
                        :imagePath="'/img/map_cluster/m'"
                        ref="cluster"
                      >
                        <gmap-marker
                          :key="index"
                          v-for="(m, index) in markers"
                          :position="m.position"
                          :icon="m.icon"
                          :animation="2"
                          @click="toggleInfoWindow(m, index, m.info)"
                        ></gmap-marker>
                      </gmap-cluster>
                      <gmap-info-window
                        :options="infoOptions"
                        :position="infoWindowPos"
                        :opened="infoWinOpen"
                        @closeclick="infoWinOpen = false"
                      >
                        <div v-html="infoContent"></div>
                      </gmap-info-window>
                      <gmap-polygon
                        v-for="poligono in poligonos"
                        :options="{ fillColor: poligono.color }"
                        :key="poligono.id"
                        :paths="poligono.path"
                        :editable="false"
                        :draggable="false"
                        @click="
                          toggleInfoWindowPoli(
                            poligono,
                            poligono.id,
                            poligono.info
                          )
                        "
                      />
                      <gmap-circle
                        v-for="circular in circunferencias"
                        :key="circular.id"
                        :center="circular.center"
                        :radius="circular.radius"
                        :info="circular.info"
                        @click="
                          toggleInfoWindowCir(
                            circular,
                            circular.id,
                            circular.info
                          )
                        "
                      ></gmap-circle>
                      <gmap-polyline
                        v-for="(polyline, id) in polylines"
                        :key="id"
                        :path="polyline.path"
                        :options="polyline.options"
                        ref="polyline"
                      ></gmap-polyline>
                    </gmap-map>
                    <div class="row">
                      <div class="col-md-12" style="text-align: center">
                        <label for class="badge bg-light ml-2 m-0 mt-0">
                          <label class="badge bg-secondary">
                            <i
                              style="font-size: 13px"
                              class="fas fa-map-marked-alt p-1 text-danger"
                            ></i>
                            <strong style="font-size: 13px">Sitio</strong>
                            <i
                              style="font-size: 13px"
                              class="fas fa-map-marked-alt p-1 text-primary"
                            ></i>
                            <strong style="font-size: 13px"
                              >Punto Gestión</strong
                            >
                          </label>
                          <label class="badge bg-light" v-if="form.carrosAct">
                            <input
                              type="checkbox"
                              v-model="form.carrosGuias"
                              @change="showCarros()"
                              :disabled="
                                form.carrosViajes || form.carrosTickets
                              "
                            />
                            <i
                              style="font-size: 13px"
                              class="fas fa-truck-moving p-1 text-success"
                            ></i>

                            <strong style="font-size: 13px"
                              >Con Guía: {{ cantGuias }}</strong
                            >
                          </label>
                          <label class="badge bg-light" v-if="form.carrosAct">
                            <input
                              type="checkbox"
                              v-model="form.carrosTickets"
                              @change="showCarros()"
                              :disabled="form.carrosViajes || form.carrosGuias"
                            />
                            <i
                              style="font-size: 13px"
                              class="fas fa-truck-moving p-1 text-indigo"
                            ></i>

                            <strong style="font-size: 13px"
                              >Con Ticket: {{ cantTickets }}</strong
                            >
                          </label>
                          <label class="badge bg-light" v-if="form.carrosAct">
                            <input
                              type="checkbox"
                              v-model="form.carrosViajes"
                              @change="showCarros()"
                              :disabled="form.carrosGuias || form.carrosTickets"
                            />
                            <i
                              style="font-size: 13px"
                              class="fas fa-truck-moving p-1 text-info"
                            >
                            </i>

                            <strong style="font-size: 13px">
                              Con Viaje Activo: {{ cantViajes }}</strong
                            >
                          </label>
                          <label class="badge bg-secondary">
                            <i
                              style="font-size: 13px"
                              class="fas fa-truck-moving p-1 text-warning"
                            ></i>
                            <strong style="font-size: 13px"
                              >Con Viaje Finalizado:
                              {{ cantInactViaje }}</strong
                            >
                            <i
                              style="font-size: 13px"
                              class="fas fa-truck-moving p-1 text-danger"
                            ></i>
                            <strong style="font-size: 13px"
                              >Sin Viaje Activo: {{ cantInact }}</strong
                            >
                          </label>
                          <i
                            style="font-size: 13px"
                            class="fas fa-truck-moving p-1 text-primary"
                          ></i>
                          <strong style="font-size: 13px"
                            >Postulado: {{ cantPost }}</strong
                          >
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-5"
                    id="trazaViaje"
                    style="display: none; position: absolute"
                  >
                    <div
                      class="card card-frontera card-outline collapsed-header"
                      id="trazaViajeHeader"
                    >
                      <div class="card-header pt-0 pb-0" id="headingOne">
                        <h5 class="mb-0">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <i class="fas fa-route text-success"></i>
                            <b>&nbsp;Administrar Traza</b>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="trazaViajeHeader"
                        data-parent="#trazaViaje"
                      >
                        <div class="card-body">
                          <div class="row">
                            <div class="form-group col-md-6">
                              <label># Viaje</label>
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                placeholder="Víaje"
                                v-model="filter.viaje_id"
                                style="font-size: 12px; background-color: #fff"
                              />
                            </div>
                            <div
                              class="form-group col-md-6"
                              v-if="!viajes.length"
                            >
                              <label>Vehículo</label>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                placeholder="Placa"
                                v-model="filter.vehiculo_id"
                                style="font-size: 12px; background-color: #fff"
                              />
                            </div>
                          </div>
                          <div
                            id="div_fechas"
                            v-show="
                              filter.checkViajes == true ||
                              filter.checkRango == true
                            "
                          >
                            <div class="row">
                              <div class="col-md-6">
                                <label>Fecha Inicio</label>
                                <input
                                  type="date"
                                  class="form-control form-control-sm"
                                  v-model="filter.fecha_inicio"
                                  style="font-size: 9px; background-color: #fff"
                                />
                              </div>
                              <div class="col-md-6">
                                <label>Fecha Fin</label>
                                <input
                                  type="date"
                                  class="form-control form-control-sm"
                                  v-model="filter.fecha_fin"
                                  style="font-size: 9px; background-color: #fff"
                                />
                              </div>
                            </div>
                          </div>
                          <div v-show="filter.vehiculo_id">
                            <div class="row float-right col-md-6 pt-2">
                              <div class="col-md-6">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    id="checkViajes"
                                    v-model="filter.checkViajes"
                                    class="custom-control-input"
                                    :disabled="filter.checkRango == true"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="checkViajes"
                                    >Viajes</label
                                  >
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    id="checkRango"
                                    v-model="filter.checkRango"
                                    class="custom-control-input"
                                    :disabled="filter.checkViajes == true"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="checkRango"
                                    >Rango</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="row col-md-12"
                            v-if="viajes.length && filter.checkViajes == true"
                          >
                            <div class="col-md-12">
                              <div class="card-body p-0">
                                <label>Viajes</label>
                                <table class="table-responsive">
                                  <tbody>
                                    <td v-for="item of viajes" :key="item.id">
                                      <tr>
                                        <button
                                          type="button"
                                          class="btn btn-sm btn-primary"
                                          @click="showTrazaViaje(item.id)"
                                        >
                                          {{ item.id }}
                                        </button>
                                      </tr>
                                    </td>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              class="form-group col-md-6"
                              v-if="
                                (puntosTraza.length &&
                                  (filter.checkRango == true ||
                                    filter.checkViajes == true)) ||
                                (puntosTraza.length && filter.viaje_id)
                              "
                            >
                              <label>Cantidad</label>
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                placeholder="Cantidad Puntos"
                                v-model="filter.cantidad"
                                style="font-size: 12px; background-color: #fff"
                              />
                            </div>
                            <div class="form-group col-md-6">
                              <br />
                              <div
                                class="btn-group float-right pt-1"
                                role="group"
                                v-if="puntosTraza[0] && filter.cantidad"
                              >
                                <button
                                  type="button"
                                  class="btn btn-success"
                                  id="atras"
                                  :disabled="!markersTraza[1]"
                                  @click="backPoint()"
                                >
                                  <i class="fas fa-angle-double-left"></i>
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-success"
                                  id="adelante"
                                  :disabled="
                                    markersTraza.length == puntosTraza.length
                                  "
                                  @click="nextPoint()"
                                >
                                  <i class="fas fa-angle-double-right"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer">
                          <div class="btn-group">
                            <button
                              type="button"
                              class="btn btn-primary"
                              @click="showTrazaViaje()"
                              v-if="
                                (filter.fecha_inicio && filter.fecha_fin) ||
                                filter.viaje_id
                              "
                            >
                              Buscar
                              <i class="fas fa-search"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-dark"
                              @click="limpiarMapa()"
                            >
                              Limpiar
                              <i class="fas fa-trash"></i>
                            </button>
                            <button
                              v-if="
                                ((filter.fecha_inicio && filter.fecha_fin) ||
                                  filter.viaje_id) &&
                                $store.getters.can(
                                  'hidrocarburos.mapas.excelTraza'
                                )
                              "
                              type="button"
                              class="btn bg-indigo text-white mr-2 align-top py-1"
                              style="cursor: pointer"
                              @click="generarTrazaExcel()"
                            >
                              <i
                                style="font-size: 21px"
                                class="far fa-file-excel"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- v-if="pathRuta.length > 0" -->
          <div
            class="col-md-12"
            v-if="
              Object.keys(vehiculosAct).length > 0 ||
              Object.keys(vehiculosPost).length > 0 ||
              Object.keys(vehiculosInact).length > 0
            "
          >
            <MapasListVehiculos ref="MapasListVehiculos" />
          </div>
        </section>
        <MapasOptions ref="MapasOptions" />
        <ViajesDetViaje ref="ViajesDetViaje" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import MapasOptions from "./MapasOptions";
import MapasListVehiculos from "./MapasListVehiculos";
import ViajesDetViaje from "../viajes/ViajesDetViaje";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import Loading from "../../../../components/Loading";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { VBPopover } from "bootstrap-vue";
import RutaPolilineasVue from "../../../admin/basico/rutas/RutaPolilineas.vue";

export default {
  name: "Mapas",
  directives: {
    "b-popover": VBPopover,
  },
  components: {
    Loading,
    MapasOptions,
    MapasListVehiculos,
    ViajesDetViaje,
    GmapCluster,
  },
  data() {
    return {
      cargando: false,
      vehiculosAct: {},
      vehiculosInact: {},
      vehiculosPost: {},
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      form: {
        carrosAct: false,
        carrosInact: false,
        carrosPost: false,
        carrosGuias: false,
        carrosTickets: false,
        carrosViajes: false,
      },
      filter: {
        checkViajes: false,
        checkRango: false,
      },
      listasForms: {
        empresas: [],
        estados: [],
        estadosPostulacion: [],
        origenes: [],
        destinos: [],
        rutas: [],
        productos: [],
        tipos_combustibles: [],
      },
      colorsArray: [
        "#FF0000",
        "#00FF00",
        "#0000FF",
        "#FFFF00",
        "#16DEC3",
        "#5B0823",
      ],
      colors: {},
      mapKey: 0,
      center: { lat: 4.651371, lng: -74.071509 },
      zoom: 6,
      markers: [],
      markersTraza: [],
      places: [],
      currentPlace: null,
      locations: {},
      poligonos: [],
      circunferencias: [],
      puntoInicialLat: null,
      puntoInicialLng: null,
      puntoFinalLat: null,
      puntoFinalLng: null,
      infoPuntoFinal: "",
      infoPuntoInicial: "",
      puntofin: null,
      vehiculo_id: null,
      latitud: null,
      longitud: null,
      puntosTraza: [],
      viajes: [],
      trazaViaje: false,
      path: [],
      det_ruta: [],
      polylines: {},
      pathPoli: [],
      polylineOptions: [],
      cantGuias: 0,
      cantTickets: 0,
      cantViajes: 0,
      cantPost: 0,
      cantInact: 0,
      cantInactViaje: 0,
    };
  },

  methods: {
    async getIndex(page = 1) {
      this.cargando = true;
      let filters = this.$refs.MapasOptions.filter;
      if (
        Object.keys(this.vehiculosAct).length > 0 ||
        Object.keys(this.vehiculosPost).length > 0 ||
        Object.keys(this.vehiculosInact).length > 0
      ) {
        let filters2 = this.$refs.MapasListVehiculos.filtros;
        Object.assign(filters, filters2);
      }
      filters.carrosAct = this.form.carrosAct;
      filters.carrosInact = this.form.carrosInact;
      filters.carrosPost = this.form.carrosPost;
      filters.carrosViajes = this.form.carrosViajes;
      filters.carrosGuias = this.form.carrosGuias;
      filters.carrosTickets = this.form.carrosTickets;

      let me = this;
      await axios
        .get("/api/hidrocarburos/mapas?page=" + page, {
          params: filters,
        })
        .then(function (response) {
          me.vehiculosAct = response.data[0];
          me.vehiculosInact = response.data[1];
          me.vehiculosPost = response.data[2];
          me.cargando = false;
        })
        .catch(function (error) {
          me.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async showDraggable() {
      if (await this.trazaViaje) {
        $("#trazaViaje").css("display", "block");
        $("#trazaViaje").draggable();
      } else {
        $("#trazaViaje").css("display", "none");
      }
    },

    showTrazaViaje(viaje) {
      this.cargando = true;
      if (viaje) {
        this.filter.viaje_id = viaje;
      }
      this.markersTraza = [];
      if (this.filter.viaje_id) {
        axios
          .get("/api/hidrocarburos/mapas/trazaViaje", {
            params: this.filter,
          })
          .then((response) => {
            this.cargando = false;
            if (response.data.arregloViaje) {
              this.filter.vehiculo_id = "";
              this.puntosTraza = response.data.arregloViaje;
              const marker = {
                lat: parseFloat(this.puntosTraza[0].latitud),
                lng: parseFloat(this.puntosTraza[0].longitud),
              };
              this.markersTraza.push({
                id: this.puntosTraza[0].id,
                position: marker,
                info: this.puntosTraza[0].ventana,
                icon: "/img/icon_punto_g_ok.png",
              });
              this.center = marker;
              this.zoom = 10;
            }
          })
          .catch(function (error) {
            this.cargando = false;
          });
      } else if (this.filter.vehiculo_id) {
        this.filter.viaje_id = "";

        if (this.filter.fecha_inicio > this.filter.fecha_fin) {
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          var fecha_menor = new Date(this.filter.fecha_inicio);
          var fecha_mayor = new Date(this.filter.fecha_fin);
          var diff = fecha_mayor - fecha_menor;
          var fecha_limite = diff / (1000 * 60 * 60 * 24);
          if (this.filter.checkRango == true) {
            if (fecha_limite > 5) {
              this.filter.fecha_fin = null;
              this.filter.fecha_inicio = null;
              this.$swal({
                icon: "error",
                title: `Las fechas no pueden ser mayor a 5 días...`,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargando = false;
            } else {
              axios
                .get("/api/hidrocarburos/mapas/trazaViaje", {
                  params: this.filter,
                })
                .then((response) => {
                  this.cargando = false;
                  if (response.data.arregloVehiculo2) {
                    this.puntosTraza = response.data.arregloVehiculo2;
                    this.viajes = [];
                    const marker = {
                      lat: parseFloat(this.puntosTraza[0].latitud),
                      lng: parseFloat(this.puntosTraza[0].longitud),
                    };
                    this.markersTraza.push({
                      id: this.puntosTraza[0].id,
                      position: marker,
                      info: this.puntosTraza[0].ventana,
                      icon: "/img/icon_punto_g_ok.png",
                    });
                    this.center = marker;
                    this.zoom = 10;
                  } else {
                    this.viajes = response.data.arregloVehiculo1;
                  }
                })
                .catch(function (error) {
                  this.cargando = false;
                });
            }
          } else {
            axios
              .get("/api/hidrocarburos/mapas/trazaViaje", {
                params: this.filter,
              })
              .then((response) => {
                this.cargando = false;
                if (response.data.arregloVehiculo2) {
                  this.puntosTraza = response.data.arregloVehiculo2;
                  this.viajes = [];
                  const marker = {
                    lat: parseFloat(this.puntosTraza[0].latitud),
                    lng: parseFloat(this.puntosTraza[0].longitud),
                  };
                  this.markersTraza.push({
                    id: this.puntosTraza[0].id,
                    position: marker,
                    info: this.puntosTraza[0].ventana,
                    icon: "/img/icon_punto_g_ok.png",
                  });
                  this.center = marker;
                  this.zoom = 10;
                } else {
                  this.viajes = response.data.arregloVehiculo1;
                }
              })
              .catch(function (error) {
                //this.cargando = false;
              });
          }
        }
      }
    },

    generarTrazaExcel() {
      axios({
        method: "POST",
        url: "/api/hidrocarburos/mapas/exportTraza",
        data: { filtros: this.filter },
      })
        .then((response) => {
          let data = response.data;
          if (data.msj) {
            this.$swal({
              icon: "error",
              title: data.msj,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
          } else {
            this.$parent.cargando = false;
            location.href = data.url;
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    nextPoint() {
      let dist =
        parseInt(this.markersTraza.length - 1) + parseInt(this.filter.cantidad);
      for (let i = this.markersTraza.length - 1; i < dist; i++) {
        if (this.markersTraza.length < this.puntosTraza.length) {
          const marker = {
            lat: parseFloat(this.puntosTraza[i].latitud),
            lng: parseFloat(this.puntosTraza[i].longitud),
          };
          this.markersTraza.push({
            id: this.puntosTraza[i].id,
            position: marker,
            info: this.puntosTraza[i].ventana,
            icon: "/img/icon_viaje.png",
          });
          this.center = marker;
          this.zoom = 10;
        } else {
          const marker = {
            lat: parseFloat(this.puntosTraza[i].latitud),
            lng: parseFloat(this.puntosTraza[i].longitud),
          };
          this.markersTraza.push({
            id: this.puntosTraza[i].id,
            position: marker,
            info: this.puntosTraza[i].ventana,
            icon: "/img/icon_punto_g.png",
          });
          this.$swal({
            title: "Fin del recorrido!",
            text: "No se encontraron más tramas para este viaje",
            icon: "info",
          });

          break;
        }
      }
    },

    backPoint() {
      let dist =
        parseInt(this.markersTraza.length - 1) - parseInt(this.filter.cantidad);
      for (let i = this.markersTraza.length - 1; i > dist; i--) {
        this.markersTraza.splice(i, 1);
      }
    },

    limpiarMapa() {
      this.puntosTraza = [];
      this.markersTraza = [];
      this.viajes = [];
      this.filter = {};
    },

    async showCarros() {
      this.markers = [];
      let filters = this.$refs.MapasOptions.filter;
      filters.carrosAct = await this.form.carrosAct;
      filters.carrosInact = await this.form.carrosInact;
      filters.carrosPost = await this.form.carrosPost;
      filters.carrosViajes = await this.form.carrosViajes;
      filters.carrosGuias = await this.form.carrosGuias;
      filters.carrosTickets = await this.form.carrosTickets;
      if (filters.geocerca != null) {
        this.filterResult(filters.geocerca);
      }
      this.cargando = true;
      if (
        this.form.carrosAct ||
        this.form.carrosInact ||
        this.form.carrosPost
      ) {
        this.cantGuias = 0;
        this.cantTickets = 0;
        this.cantViajes = 0;
        this.cantInact = 0;
        this.cantInactViaje = 0;
        this.cantPost = 0;
        const me = this;
        await axios
          .get("/api/hidrocarburos/mapas/showCarros", {
            params: filters,
          })
          .then(async (response) => {
            this.getIndex();
            let loc = response.data;
            this.cantGuias = loc[1];
            this.cantTickets = loc[2];
            this.cantViajes = loc[3];
            this.cantInact = loc[4];
            this.cantInactViaje = loc[5];
            this.cantPost = loc[6];
            this.det_ruta = loc[7];
            if (this.det_ruta.length > 0) {
              await this.RutaPolilineas();
            }
            loc[0].forEach((loc1) => {
              const marker = {
                lat: parseFloat(loc1.lat),
                lng: parseFloat(loc1.lon),
              };
              this.markers.push({
                position: marker,
                icon: loc1.icon,
                info: loc1.ventana,
                center: {
                  marker,
                },
              });
            });
          })
          .catch(function (error) {
            me.cargando = false;
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        this.getIndex();
      }
    },

    filterResult(geo) {
      for (let i = 0; i < this.poligonos.length; i++) {
        if (this.poligonos[i].id == geo.id) {
          const marker = {
            lat: parseFloat(this.poligonos[i].path[0].lat),
            lng: parseFloat(this.poligonos[i].path[0].lng),
          };
          this.markersTraza.push({
            position: marker,
            info: this.poligonos[i].info,
          });
          this.center = marker;
          this.zoom = 15;
        }
      }
    },

    cleanMarkers() {
      this.markersTraza = [];
    },

    async showGeocercas() {
      if (await this.form.geocercas) {
        this.cargando = true;
        await axios
          .get("/api/hidrocarburos/mapas/showGeocercas")
          .then((response) => {
            this.cargando = false;
            this.locations = response.data.gps_fin;
            this.locations.geocerca_poli.forEach((geocerca) => {
              if (geocerca.puntos.length > 0) {
                let puntos = [];
                geocerca.puntos.forEach((punto) => {
                  puntos.push({
                    lng: parseFloat(punto.longitud),
                    lat: parseFloat(punto.latitud),
                  });
                });

                this.poligonos.push({
                  id: geocerca.id,
                  name: geocerca.desc,
                  path: puntos,
                  info: geocerca.ventana,
                  color: geocerca.color,
                });
              }
            });
            for (var j = 0; j < this.locations.geocerca_cir.length; j++) {
              if (this.locations.geocerca_cir[j]["lat"] !== "") {
                this.circunferencias.push({
                  center: {
                    lat: parseFloat(this.locations.geocerca_cir[j]["lat"]),
                    lng: parseFloat(this.locations.geocerca_cir[j]["lon"]),
                  },
                  radius:
                    parseFloat(this.locations.geocerca_cir[j]["radio"]) * 1000,
                  info: this.locations.geocerca_cir[j]["ventana"],
                });
              }
            }
          })
          .catch(function (error) {
            this.cargando = false;
          });
      } else {
        this.locations = {};
        this.circunferencias = [];
        this.poligonos = [];
      }
    },

    async showGeocercasProhibidas() {
      if (await this.form.geocercasProhibidas) {
        this.cargando = true;
        await axios
          .get("/api/hidrocarburos/mapas/showGeocercasProhibidas")
          .then((response) => {
            this.cargando = false;
            this.locations = response.data.gps_fin;
            this.locations.geocerca_poli.forEach((geocerca) => {
              if (geocerca.puntos.length > 0) {
                let puntos = [];
                geocerca.puntos.forEach((punto) => {
                  puntos.push({
                    lng: parseFloat(punto.longitud),
                    lat: parseFloat(punto.latitud),
                  });
                });

                this.poligonos.push({
                  id: geocerca.id,
                  name: geocerca.desc,
                  path: puntos,
                  info: geocerca.ventana,
                  color: geocerca.color,
                });
              }
            });
            for (var j = 0; j < this.locations.geocerca_cir.length; j++) {
              if (this.locations.geocerca_cir[j]["lat"] !== "") {
                this.circunferencias.push({
                  center: {
                    lat: parseFloat(this.locations.geocerca_cir[j]["lat"]),
                    lng: parseFloat(this.locations.geocerca_cir[j]["lon"]),
                  },
                  radius:
                    parseFloat(this.locations.geocerca_cir[j]["radio"]) * 1000,
                  info: this.locations.geocerca_cir[j]["ventana"],
                });
              }
            }
          })
          .catch(function (error) {
            this.cargando = false;
          });
      } else {
        this.locations = {};
        this.circunferencias = [];
        this.poligonos = [];
      }
    },

    toggleInfoWindow: function (marker, idx, ventana) {
      this.infoWindowPos = marker.position;
      this.infoContent = ventana;
      this.center = marker.position;

      //comprueba si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    toggleInfoWindowPoli: function (poli, idx, ventana) {
      this.infoWindowPos = poli.path[0];
      this.infoContent = ventana;
      this.center = poli.path[0];

      //compruebe si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    toggleInfoWindowCir: function (cir, idx, ventana) {
      this.infoWindowPos = cir.center;
      this.infoContent = ventana;
      this.center = cir.center;

      //compruebe si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    getEmpresa() {
      axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    getOrigenes() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.origenes = response.data;
      });
    },

    getDestinos() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.destinos = response.data;
      });
    },

    getRutas() {
      axios.get("/api/admin/rutas/lista").then((response) => {
        this.listasForms.rutas = response.data;
      });
    },

    getProductos() {
      axios.get("/api/hidrocarburos/Productos/lista").then((response) => {
        this.listasForms.productos = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/39").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTiposCombustibles() {
      axios.get("/api/lista/24").then((response) => {
        this.listasForms.tipos_combustibles = response.data;
      });
    },

    getEstadosPostulacion() {
      axios.get("/api/lista/65").then((response) => {
        this.listasForms.estadosPostulacion = response.data;
      });
    },

    async RutaPolilineas() {
      this.polylines = {};
      this.pathPoli = await this.det_ruta;

      for (let r = 0; r < this.pathPoli.length; r++) {
        const ruta = this.pathPoli[r];
        const color = this.colorsArray[r % this.colorsArray.length]; // selecciona un color diferente para cada polilínea

        const polyline = {
          path: [], // construye el arreglo de puntos de la polilínea
          options: {
            strokeColor: color,
            strokeOpacity: 0.5,
            strokeWeight: 6,
          },
        };
        if (this.pathPoli[r].ruta_polilineas.length > 0) {
          // agrega cada punto de la ruta al arreglo de puntos de la polilínea
          for (let i = 0; i < this.pathPoli[r].ruta_polilineas.length; i++) {
            if (i == 0) {
              const punto = this.pathPoli[r].ruta_polilineas[i];
              polyline.path.push({
                lat: parseFloat(punto.lat_ini),
                lng: parseFloat(punto.lon_ini),
              });
            }
            const punto = this.pathPoli[r].ruta_polilineas[i];
            polyline.path.push({
              lat: parseFloat(punto.lat_fin),
              lng: parseFloat(punto.lon_fin),
            });
          }
          // agrega la polilínea al objeto de polilíneas, utilizando el ID de la ruta como clave
          this.$set(this.polylines, ruta.id, polyline);
        }
      }
      this.mapKey++; // aumenta la clave del mapa
    },
  },

  mounted() {
    this.getEmpresa();
    this.getOrigenes();
    this.getDestinos();
    this.getRutas();
    this.getEstados();
    this.getTiposCombustibles();
    this.getEstadosPostulacion();
    this.getProductos();
  },
};
</script>
