<template>
  <div>
    <!-- Modal -->
    <div id="modal_aside_mapa" class="modal fixed-left fade" role="dialog">
      <div class="modal-dialog modal-dialog-aside" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera pt-1 pb-1">
            <h3 style="font-size: 16px" class="modal-title text-white">
              <b>Opciones Mapa</b>
            </h3>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="viaje_id"># Viaje</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="viaje_id"
                  placeholder="# Viaje"
                  v-model="filter.viaje_id"
                />
              </div>
            </div>
            <div class="row" v-if="$parent.form.geocercas == true">
              <div
                class="col-md-11"
                :class="
                  $parent.markersTraza.length > 0 ? 'col-md-11' : 'col-md-12'
                "
              >
                <label for="geocerca">Geocerca</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="filter.geocerca"
                  placeholder="Geocerca"
                  label="desc"
                  :options="$parent.locations.geocerca_poli"
                ></v-select>
              </div>
              <div class="col-md-1 pt-4" v-if="$parent.markersTraza.length > 0">
                <button
                  type="button"
                  class="btn btn-dark btn-sm"
                  @click="$parent.cleanMarkers()"
                >
                  <i class="fas fa-brush"></i>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label for="origen">Origen</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  placeholder="Origen"
                  v-model="origen"
                  label="nombre"
                  :options="$parent.listasForms.origenes"
                  @input="selectOrigen()"
                  multiple
                ></v-select>
              </div>
              <div class="col-md-6">
                <label for="Destino">Destino</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  placeholder="Destino"
                  v-model="destino"
                  label="nombre"
                  :options="$parent.listasForms.destinos"
                  @input="selectDestino()"
                  multiple
                ></v-select>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="Ruta">Ruta</label>
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    v-model="ruta"
                    label="nombre"
                    :options="$parent.listasForms.rutas"
                    @input="selectRuta()"
                    placeholder="Ruta"
                    multiple
                  ></v-select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="empresa">Empresa</label>
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    placeholder="Razon Social"
                    v-model="empresas"
                    label="razon_social"
                    :options="$parent.listasForms.empresas"
                    @input="selectEmpresa()"
                    multiple
                  ></v-select>
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="estado">Estado</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="estados"
                  label="descripcion"
                  :options="$parent.listasForms.estados"
                  @input="selectEstado()"
                  placeholder="Estados"
                  :disabled="$parent.form.carrosPost"
                  multiple
                ></v-select>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="vehículo">Tipo Combustible</label>
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    v-model="tipo_combustible"
                    placeholder="Tipo Combustible"
                    label="descripcion"
                    :options="$parent.listasForms.tipos_combustibles"
                    @input="seleccionarTipoCombustible()"
                    multiple
                  ></v-select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="vehículo">Vehículo</label>
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    v-model="filter.vehiculo"
                    placeholder="Placa"
                    taggable
                    multiple
                  ></v-select>
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="guia"># Guía</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="filter.guia"
                  placeholder="# Guía"
                  taggable
                  multiple
                ></v-select>
              </div>
              <div class="form-group col-md-12">
                <label for="ticket"># Ticket</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="filter.ticket"
                  placeholder="# Ticket"
                  taggable
                  multiple
                ></v-select>
              </div>
              <div class="form-group col-md-12">
                <label>Producto</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="productos"
                  placeholder="Nombre Producto"
                  label="nombre"
                  :options="$parent.listasForms.productos"
                  @input="seleccionarProducto()"
                  multiple
                ></v-select>
              </div>
              <div
                class="form-group col-md-12"
                v-show="$parent.form.carrosPost"
              >
                <label>Rango de fechas de la Postulación</label>
                <div class="row">
                  <div class="col-md-6">
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filter.fecha_inicio"
                      @blur="validaFechas()"
                    />
                  </div>
                  <div class="col-md-6">
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filter.fecha_fin"
                      @blur="validaFechas()"
                    />
                  </div>
                </div>
              </div>
              <div
                class="form-group col-md-12"
                v-show="$parent.form.carrosPost"
              >
                <label for="estado">Estado Postulación</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="estadosPostulacion"
                  label="descripcion"
                  :options="$parent.listasForms.estadosPostulacion"
                  @input="selectEstadoPostulacion()"
                  placeholder="Estados Postulación"
                  multiple
                ></v-select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
              <i class="fas fa-times"></i>
            </button>
            <button
              type="button"
              class="btn btn-dark"
              @click="limpiarFiltros()"
            >
              Limpiar
              <i class="fas fa-trash"></i>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="$parent.showCarros()"
            >
              Buscar
              <i class="fas fa-search"></i>
            </button>
            <button
              v-if="
                ($parent.form.carrosAct ||
                  $parent.form.carrosInact ||
                  $parent.form.carrosPost) &&
                  $parent.$store.getters.can(
                    'hidrocarburos.mapas.excelVehiculos'
                  )
              "
              type="button"
              class="btn bg-indigo text-white mr-2 align-top py-1"
              style="cursor: pointer"
              @click="generarListadoExcel()"
            >
              <i style="font-size: 21px" class="far fa-file-excel"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";

export default {
  name: "MapasOptions",
  components: {
    vSelect,
  },
  data() {
    return {
      filter: {
        estado: [],
        empresa: [],
        estadoPostulacion: [],
        producto: [],
        tipo_combustible: [],
        origen_id: [],
        destino_id: [],
        ruta_id: [],
        geocerca: null,
      },
      estados: null,
      estadosPostulacion: null,
      productos: null,
      tipo_combustible: null,
      empresas: null,
      origen: null,
      destino: null,
      ruta: null,
    };
  },

  methods: {
    selectEmpresa() {
      this.filter.empresa = this.empresas.map((e) => e.id);
    },
    selectOrigen() {
      this.filter.origen_id = this.origen.map((e) => e.id);
    },
    selectDestino() {
      this.filter.destino_id = this.destino.map((e) => e.id);
    },
    selectRuta() {
      this.filter.ruta_id = this.ruta.map((e) => e.id);
    },
    selectEstado() {
      this.filter.estado = this.estados.map((e) => e.numeracion);
    },
    selectEstadoPostulacion() {
      this.filter.estadoPostulacion = this.estadosPostulacion.map(
        (e) => e.numeracion
      );
    },
    seleccionarProducto() {
      this.filter.producto = this.productos.map((e) => e.id);
    },

    seleccionarTipoCombustible() {
      this.filter.tipo_combustible = this.tipo_combustible.map(
        (e) => e.numeracion
      );
    },

    generarListadoExcel() {
      this.$parent.cargando = true;
      let filters = this.filter;
      filters.carrosAct = this.$parent.form.carrosAct;
      filters.carrosInact = this.$parent.form.carrosInact;
      filters.carrosPost = this.$parent.form.carrosPost;
      filters.carrosViajes = this.$parent.form.carrosViajes;
      filters.carrosGuias = this.$parent.form.carrosGuias;
      filters.carrosTickets = this.$parent.form.carrosTickets;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/mapas/export",
        data: { filtros: filters },
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    validaFechas() {
      // Se valida que la fecha inicial sea menor que la fecha final
      if (this.filter.fecha_inicio > this.filter.fecha_fin) {
        this.filter.fecha_fin = null;
        this.$swal({
          icon: "error",
          title: `La fecha inicio no puede ser mayor a la fecha fin...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    limpiarFiltros() {
      this.filter = {};
      this.filter.estado = [];
      this.filter.estadoPostulacion = [];
      this.filter.empresa = [];
      this.filter.producto = [];
      this.filter.tipo_combustible = [];
      this.estados = null;
      this.estadosPostulacion = null;
      this.productos = null;
      this.empresas = null;
      this.origen = null;
      this.destino = null;
      this.tipo_combustible = [];
      this.ruta = null;
      this.$parent.pathRuta = [];
      this.$parent.pathPoli = [];
    },
  },
};
</script>
<style>
.modal .modal-dialog-aside {
  width: 440px;
  max-width: 50%;
  height: 100%;
  margin: 0;
  transform: translate(2);
  transition: transform 0.2s;
}
.modal .modal-dialog-aside .modal-content {
  height: inherit;
  border: 1;
  border-radius: 1;
}
.modal .modal-dialog-aside .modal-content .modal-body {
  overflow-y: auto;
}
.modal.fixed-left .modal-dialog-aside {
  margin-left: auto;
  transform: translateX(100%);
}
.modal.fixed-right .modal-dialog-aside {
  margin-right: auto;
  transform: translateX(-100%);
}
.modal.show .modal-dialog-aside {
  transform: translateX(0);
}
</style>
